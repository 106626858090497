import {
  FieldCompatibleProps,
  FileInfo,
  FileUpload,
  FileUploadProps,
  Text,
  useToasts,
} from '@applyboard/crystal-ui'
import { DocumentType } from 'applications-types-lib'
import { DocumentTags } from '../../../utils/enums'
import {
  ApplicationDocument,
  IApplicationDocument,
  UploadedApplicationDocument,
} from '../ApplicationDocuments'
import { StudentApplication, UploadFileData } from '../types'
import { ApplicationFileList } from './ApplicationFileList'
import { useApplicationFormContext } from './ApplicationForm'
import { transformFileInfoToUploadFileData } from '../../../utils/transformFileInfoToUploadFileData'
import { Loading } from '../../Loading'

// Workaround to have an Array of objects as value (up to crystal 2.4)
export type FileUploadAsFieldArray = React.FunctionComponent<
  FieldCompatibleProps<unknown, HTMLElement> & {
    allowedFileTypes: string[]
    application: StudentApplication
    disabled?: boolean
    fileLimit?: number
    fileType: DocumentTags
    onChange: (newValue: Array<FileUploadFieldValue>) => void
    onRemove?: (fileId: string) => void
    section: string
    showHistory: boolean
    value: Array<FileUploadFieldValue>
  }
>

export type FileUploadFieldValue = UploadFileData & { id: string }

interface FileUploadFieldPropsV2 {
  application: StudentApplication
  fileLimit?: number
  fileType: DocumentTags

  onChange: (newValue: Array<FileUploadFieldValue>) => void

  /** We are hiding the form & providing a secondary interface to manipulate files if the upload limit is exceeded.
   * This is required to manipulate form values when files are interacted with in the secondary interface
   */
  onRemove?: (fileId: string) => void

  /** Id/label of field, similar to name */
  section: string

  showHistory: boolean

  value: Array<FileUploadFieldValue>

  /** It overrides the default logic to show/hide the upload component.
   * The purpose of this is because some documents (i.e passport) are handled outside of logic
   */
  hideUploadComponent?: boolean
  showLoading?: boolean
  multiple?: boolean
  presetValue?: Array<IApplicationDocument>
}

export function FileUploadField({
  application,
  disabled,
  fileLimit,
  fileType,
  label,
  onChange,
  onRemove,
  section,
  showHistory,
  value,
  hideUploadComponent,
  showLoading,
  multiple = true,
  presetValue = [],
  ...otherProps
}: FileUploadProps & FileUploadFieldPropsV2) {
  const toast = useToasts()

  const { addPendingDelete, addPendingUpload, getObservableFiles } = useApplicationFormContext()

  function hasMetFileLimit() {
    return (
      fileLimit &&
      Object.keys(getObservableFiles({ fileType, sectionReference: section })).length === fileLimit
    )
  }

  function hasExceededFileLimit() {
    return (
      fileLimit &&
      Object.keys(getObservableFiles({ fileType, sectionReference: section })).length > fileLimit
    )
  }

  function shouldShowUploadField() {
    return !hideUploadComponent && !disabled && !hasExceededFileLimit() && !hasMetFileLimit()
  }

  function getVisibleFiles() {
    let visibleFiles = Object.entries(getObservableFiles({ fileType, sectionReference: section }))
      .filter(([id, _file]) => {
        const isFileInFormValue = value.some(document => id === document.id)

        // TODO:: this filtering logic relies on us never populatiing the file upload values in the form. Keep an eye on it
        if (shouldShowUploadField() && isFileInFormValue) return false
        if (showHistory)
          return application.attributes?.files?.[id]?.activeRecord && !isFileInFormValue
        return true
      })
      .map(([id, file]) => {
        if (showHistory) {
          return UploadedApplicationDocument.create(application, id, { includeHistory: true })
        }
        const fileMeta =
          'meta' in application && 'files' in application.meta
            ? application.meta.files[id]
            : undefined
        return new ApplicationDocument({
          id,
          tags: [fileType],
          url: fileMeta?.download?.url,
          name: file.fileName,
          section: section,
          uploadDate: file.uploadedAt,
        })
      })
      .sort((f1, f2) => {
        return Number(f2.uploadDate) - Number(f1.uploadDate)
      })
    return visibleFiles
  }

  function onRemoveFile(fileId: string) {
    addPendingDelete(fileId)
    if (onRemove) onRemove(fileId)
  }

  function transformValue(value: (FileUploadFieldValue | FileInfo)[]) {
    return (value || []).map((value: FileInfo | FileUploadFieldValue) => {
      const { id, ...fileUploadData } = transformFileInfoToUploadFileData(value, {
        type: fileType as unknown as DocumentType,
        sectionReference: section || '',
      })

      addPendingUpload(id, fileUploadData)
      return {
        ...fileUploadData,
        id: id,
      }
    })
  }

  return (
    <>
      {shouldShowUploadField() ? (
        <FileUpload
          {...otherProps}
          label={label}
          multiple={multiple} // keep this true, FileUpload has strange behaviour when this is false
          value={value}
          onChange={value => {
            onChange(transformValue(value))
            if (
              fileLimit &&
              Object.keys(getObservableFiles({ fileType, sectionReference: section })).length +
                value.length >
                fileLimit
            ) {
              toast.negative(new Error(`Only ${fileLimit} file(s) can be uploaded.`))
            }
          }}
          onRemoveFile={file => addPendingDelete(file.id)} // unable to modify this definition, actual file type is UploadFileData
        />
      ) : (
        <>{label}</>
      )}
      {showLoading ? <Loading /> : null}
      {hasExceededFileLimit() ? (
        <Text
          variant={'bodyM'}
        >{`Please remove one or more files to continue uploading as the max number of files allowed is ${fileLimit}`}</Text>
      ) : null}
      <ApplicationFileList
        application={application}
        disabled={disabled}
        files={[...getVisibleFiles(), ...presetValue]}
        onRemove={onRemoveFile}
        showHistory={showHistory}
      />
    </>
  )
}
