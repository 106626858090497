import { UpdateFileMetaData } from 'applications-types-lib'
import { UploadFileData } from '../components/Application'

export function stripFileFromData(fileData?: Record<string, UploadFileData | null>) {
  if (!fileData || !Object.keys(fileData).length) return null

  const strippedFileData: Record<string, UpdateFileMetaData | null> = {}

  Object.keys(fileData).forEach(fileId => {
    if (fileData[fileId] === null) {
      strippedFileData[fileId] = null
      return
    }
    const { file, ...rest } = fileData[fileId] as UploadFileData
    strippedFileData[fileId] = rest
  })

  return strippedFileData
}
