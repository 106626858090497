import { ApiStudentRequest, request } from '../utils'
import { useAuthQuery } from './auth'

export enum DocumentProcessStatus {
  NOT_UPLOADED = 'NOT_UPLOADED', // Before S3 presigned url is used
  DOCUMENT_TYPE_VALIDATION_FAILED = 'DOCUMENT_TYPE_VALIDATION_FAILED',
  DOCUMENT_TYPE_VALIDATION_COMPLETED = 'DOCUMENT_TYPE_VALIDATION_COMPLETED',
  DOCUMENT_DATA_EXTRACTION_FAILED = 'DOCUMENT_DATA_EXTRACTION_FAILED',
  DOCUMENT_DATA_EXTRACTION_COMPLETED = 'DOCUMENT_DATA_EXTRACTION_COMPLETED',
}

export enum ConfidenceLevel {
  HIGH = 'high',
  LOW = 'low',
  MISMATCH = 'mismatch',
  NONE = 'none',
}

type ValueWithConfidence<T> = {
  value: T
  confidence: ConfidenceLevel
}

type PassportExtraction = {
  firstName?: ValueWithConfidence<string>
  familyName?: ValueWithConfidence<string>
  middleName?: ValueWithConfidence<string>
  passportNumber?: ValueWithConfidence<string>
  expiryDate?: ValueWithConfidence<string>
  nationality?: ValueWithConfidence<string>
  birthDate?: ValueWithConfidence<string>
  sex?: ValueWithConfidence<string>
  address?: {
    address?: string
    city?: string
    province?: string
    postalCode?: string
    country?: string
    confidenceLevel?: ConfidenceLevel
  }
}

export type Passport = {
  id: string
  type: 'passport'
  attributes: {
    downloadUrl?: string
    passportNumber?: string
    expiryDate?: string
    fileName: string
    documentProcessStatus?: DocumentProcessStatus
    uploadedAt: string
    processingResults?: {
      documentTypeConfidence?: ConfidenceLevel
      extractedData?: PassportExtraction
    }
  }
}

type RawGetPassportResponse = {
  data: Passport
}

type GetPassportProps = {
  id: string | undefined
  refetchCondition?: (data: RawGetPassportResponse | undefined) => boolean
}

export function useGetPassport(props: GetPassportProps) {
  const { isLoading, data, refetch } = useAuthQuery(
    ['passport', props.id],
    async () => await GetPassport(props.id),
    {
      enabled: !!props.id,
      refetchOnWindowFocus: false,
      refetchInterval: ({ state }) => {
        if (props.refetchCondition?.(state.data)) {
          return 3000
        }

        return false
      },
    },
  )

  return {
    isLoadingPassport: isLoading,
    refetchPassportData: refetch,
    passport: data,
  }
}

export async function GetPassport(id?: string) {
  return request<RawGetPassportResponse>(
    new ApiStudentRequest(`/documents/passports/${id}`, {
      method: 'GET',
    }),
    {
      isExpectedResponse: (res): res is RawGetPassportResponse => res,
    },
  )
}
