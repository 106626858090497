import { FileInfo } from '@applyboard/crystal-ui'
import { FileUploadFieldValue } from '../components/Application/ApplicationForms/FileUploadField'
import { has } from 'lodash'
import { nanoid } from 'nanoid'
import { DocumentType } from 'applications-types-lib'

function isTransformedValue(value: FileUploadFieldValue | FileInfo): boolean {
  return has(value, 'hash') && has(value, 'sectionReference') && has(value, 'type')
}

type Options = {
  type: DocumentType
  sectionReference: string
}

export function transformFileInfoToUploadFileData(
  value: FileUploadFieldValue | FileInfo,
  { type, sectionReference }: Options,
) {
  if (isTransformedValue(value)) return value as FileUploadFieldValue

  const { file } = value
  const id = nanoid()
  const fileUploadData = {
    contentType: file.type,
    file: file,
    fileName: file.name,
    hash: nanoid(),
    type,
    sectionReference,
  }

  return {
    ...fileUploadData,
    id: id,
  }
}
