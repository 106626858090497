import type { UploadFileData } from '../components/Application'
import { ApiStudentRequest, request } from '../utils'
import { useAuthMutation } from './auth'
import { GetPassport } from './useGetPassport'

export function useUploadPassport() {
  const { isPending, mutate } = useAuthMutation(async (passportDocument: UploadFileData) => {
    const response = await CreatePassport()

    // TODO: (SEM-4231) replace to 's3UploadAsync' when Admission Passport API integration is done
    await FakeUploadPassport({
      file: passportDocument.file,
      url: response.data.meta.preSignedUrls.upload.url,
      fields: response.data.meta.preSignedUrls.upload.fields,
    })

    return await GetPassport(response.data.id)
  })

  return {
    isUploadingPassport: isPending,
    uploadPassport: mutate,
  }
}

type RawUploadPassportResponse = {
  data: {
    id: string
    type: 'passport'
    meta: {
      preSignedUrls: {
        upload: {
          url: string
          fields: Record<string, any>
        }
      }
    }
  }
}

async function CreatePassport() {
  return request<RawUploadPassportResponse>(
    new ApiStudentRequest('/documents/passports', {
      method: 'POST',
      body: undefined,
    }),
    {
      isExpectedResponse: (res): res is RawUploadPassportResponse => res,
    },
  )
}

// TODO: (SEM-4231) Delete when Admission Passport API integration is done
async function FakeUploadPassport({
  url,
}: {
  file: File
  url: string
  fields: Record<string, any>
}) {
  return request(
    new ApiStudentRequest(url, {
      method: 'POST',
      body: undefined,
    }),
    {
      isExpectedResponse: (res): res is string => {
        return true // correct response is empty string
      },
    },
  )
}
