import { FileData, FileMetaData } from 'applications-types-lib'
import { getFilesOfType } from '../../../components/Application/ApplicationForms/utils'
import { DocumentTags } from '../../../utils/enums'
import { Flex, Notice, NoticeProps } from '@applyboard/crystal-ui'
import { ApplicationResource } from '@backend/sanitize/application/types'

type DecisionBannerProps = {
  applicationAttributes: ApplicationResource['attributes']
}

export function DecisionBanner(props: DecisionBannerProps) {
  const findLatestFile = (): FileMetaData | null => {
    if (props.applicationAttributes?.files === undefined) {
      return null
    }

    const files = getFilesOfType(
      [
        DocumentTags.PRE_OFFER_LETTER,
        DocumentTags.WAITLIST_LETTER,
        DocumentTags.FINAL_OFFER_LETTER,
        DocumentTags.DECLINED_LETTER,
      ],
      props.applicationAttributes?.files as FileData,
      { activeFiles: true },
    )
    const filesWithoutNull = Object.entries(files).filter(file => file[1] !== null)

    if (filesWithoutNull.length === 0) {
      return null
    }

    const latestFile = filesWithoutNull.reduce(
      (fileA, fileB) => {
        return new Date(fileA[1]!.uploadedAt) > new Date(fileB[1]!.uploadedAt) ? fileA : fileB
      },
      ['initValue', { uploadedAt: -8640000000000000 } as unknown as FileMetaData],
    )

    return latestFile[1]
  }

  let bannerText = ''
  let intent: NoticeProps['intent'] = 'positive'
  const latestFile = findLatestFile()
  if (!latestFile) {
    return null
  }

  const isPreOffer = (latestFile.type as unknown as DocumentTags) === DocumentTags.PRE_OFFER_LETTER
  const isWaitlistOffer =
    (latestFile.type as unknown as DocumentTags) === DocumentTags.WAITLIST_LETTER
  const isFinalOffer =
    (latestFile.type as unknown as DocumentTags) === DocumentTags.FINAL_OFFER_LETTER
  const isRejection = (latestFile.type as unknown as DocumentTags) === DocumentTags.DECLINED_LETTER

  if (isPreOffer) {
    bannerText = 'Congratulations! You have received a pre-offer'
    intent = 'positive'
  } else if (isWaitlistOffer) {
    bannerText = 'Congratulations! You have been waitlisted'
    intent = 'secondary'
  } else if (isFinalOffer) {
    bannerText = 'Congratulations! You have received a final offer'
    intent = 'positive'
  } else if (isRejection) {
    bannerText =
      'After careful consideration, we regret to inform you that this application has not been accepted.'
    intent = 'negative'
  } else {
    return null
  }

  return (
    <Flex>
      <Notice intent={intent}>{bannerText}</Notice>
    </Flex>
  )
}
