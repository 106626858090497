import {
  Checkbox,
  CheckboxProps,
  Combobox,
  createDatePickerValidator,
  createForm,
  DatePicker,
  Divider,
  Flex,
  Heading,
  Notice,
  Select,
  Text,
  TextInput,
  TextInputProps,
  useToasts,
} from '@applyboard/crystal-ui'
import { find, get, isEqual } from 'lodash'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { GENDER_NAMES, GenderCode } from 'schools-domain-backend-utils/dist/common-types/gender'
import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { GenericError } from '../../../utils'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { StudentApplication } from '../types'
import { useApplicationFormContext } from './ApplicationForm'
import { useGetDestinationCountry } from '../../../hooks/useGetDestinationCountry'
import { useEffect, useState, ComponentType } from 'react'
import { getFilesOfType } from './utils'
import { DestinationCountryStatus, DocumentTags } from '../../../utils/enums'
import { DocumentType, FileData } from 'applications-types-lib'
import { nanoid } from 'nanoid'
import { FileUploadField } from './FileUploadField'
import { useUploadPassport } from '../../../hooks/useUploadPassport'
import { transformFileInfoToUploadFileData } from '../../../utils/transformFileInfoToUploadFileData'
import { IApplicationDocument } from '../ApplicationDocuments'
import {
  ConfidenceLevel,
  DocumentProcessStatus,
  Passport,
  useGetPassport,
} from '../../../hooks/useGetPassport'
import { Loading } from '../../Loading'
import { LoadingField } from '../../LoadingField'

type PersonalInformationFormFields = {
  passportNumber: string
  passportExpiryDate: string
  passportFiles: Array<{
    id: string
    file: File
  }>
  immigrationStatus: string
  immigrationFiles: Array<{
    id: string
    file: File
  }>
  givenName: string
  middleName: string
  familyName: string

  dateOfBirth: string
  nationality: string
  gender: string
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
  mailingAddressCountry: string
  mailingAddressLine1: string
  mailingAddressLine2: string
  mailingAddressCity: string
  mailingAddressProvince: string
  mailingAddressPostalCode: string
  isMailingAddressResidentialAddress: boolean
}

const UPLOAD_LIMIT = 1

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<PersonalInformationFormFields>()

type PersonalInformationTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSubmit?: () => void
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: GenericError) => void
  updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
  formId: string
}

export function PersonalInformationTab(props: Readonly<PersonalInformationTabProps>) {
  const passportId = Object.values(
    props.application.attributes?.statusAndCitizenship?.passports || {},
  )[0]?.passportId
  const { isLoadingPassport, passport } = useGetPassport({
    id: passportId,
    refetchCondition: data => {
      if (
        data?.data.attributes.documentProcessStatus !==
          DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_FAILED &&
        data?.data.attributes.documentProcessStatus !==
          DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_COMPLETED
      ) {
        return true
      }

      return false
    },
  })

  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()
  const { destinationCountryName } = useGetDestinationCountry()

  useEffect(() => {
    resetFiles(
      getFilesOfType(
        [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
        props.application?.attributes?.files as FileData,
      ),
    )
  }, [props.application?.attributes?.files, resetFiles])

  if (isLoadingPassport) {
    return <Loading />
  }

  return (
    <Form
      id={props.formId}
      defaultValues={{
        passportNumber: passport?.data.attributes.passportNumber || '',
        passportExpiryDate: passport?.data.attributes.expiryDate
          ? convertTimelessDateStrToLocalDate(passport.data.attributes.expiryDate).toISOString()
          : '',
        passportFiles: [],
        immigrationStatus:
          props.application?.attributes?.statusAndCitizenship?.destinationCountryStatus || '',
        immigrationFiles: [],
        givenName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.givenName ||
          '',
        middleName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.middleName || '',
        familyName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.familyName || '',

        dateOfBirth: props.application?.attributes?.personalInformation?.basicPersonalInformation
          ?.dateOfBirth
          ? convertTimelessDateStrToLocalDate(
              props.application.attributes.personalInformation?.basicPersonalInformation
                ?.dateOfBirth,
            ).toISOString()
          : '',
        nationality:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.nationality || '',
        gender:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.gender ||
          '',
        residentialAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.country || '',
        residentialAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine1 || '',
        residentialAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine2 || '',
        residentialAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress?.city ||
          '',
        residentialAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.province || '',
        residentialAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.postalCode || '',
        isMailingAddressResidentialAddress: areAddressesEqual({
          application: props.application,
        }),
        mailingAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.country ||
          '',
        mailingAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine1 || '',
        mailingAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine2 || '',
        mailingAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.city || '',
        mailingAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.province ||
          '',
        mailingAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.postalCode || '',
      }}
      onSubmit={data => {
        if (props.disabled) {
          props.onSuccess()
        } else {
          props.updateApplication(
            {
              attributes: {
                personalInformation: {
                  basicPersonalInformation: {
                    givenName: data?.givenName,
                    middleName: data?.middleName,
                    familyName: data?.familyName,

                    dateOfBirth: data?.dateOfBirth?.substring(0, 10),
                    nationality: (data?.nationality as CountryIsoCode) || null,
                    gender: (data?.gender as GenderCode) || null,
                  },
                  addresses: {
                    residentialAddress: {
                      country: data.residentialAddressCountry as CountryIsoCode,
                      addressLine1: data.residentialAddressLine1,
                      addressLine2: data.residentialAddressLine2,
                      city: data.residentialAddressCity,
                      province: data.residentialAddressProvince,
                      postalCode: data.residentialAddressPostalCode,
                    },
                    mailingAddress: {
                      country: data.mailingAddressCountry as CountryIsoCode,
                      addressLine1: data.mailingAddressLine1,
                      addressLine2: data.mailingAddressLine2,
                      city: data.mailingAddressCity,
                      province: data.mailingAddressProvince,
                      postalCode: data.mailingAddressPostalCode,
                    },
                  },
                },
                statusAndCitizenship: {
                  destinationCountryStatus: data.immigrationStatus as DestinationCountryStatus,
                  passports: passportId
                    ? {
                        [passportId]: {
                          passportNumber: data.passportNumber,
                          passportExpiry: data.passportExpiryDate?.substring(0, 10),
                        },
                      }
                    : undefined,
                },
              },
              files: pendingFileUploadState,
            },
            {
              onSuccess: response => {
                resetFiles(
                  getFilesOfType(
                    [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
                    response.data?.attributes?.files as FileData,
                  ),
                )
                props.onSuccess(response)
              },
              onError: props.onError,
            },
          )
        }
      }}
    >
      <PersonalInformationTabFields
        disabled={props.disabled}
        application={props.application}
        destinationCountryName={destinationCountryName}
        passport={passport?.data}
      />
    </Form>
  )
}

type PersonalInformationFormFieldsProps = {
  disabled?: boolean
  application: StudentApplication
  destinationCountryName: string
  passport?: Passport
}

function PersonalInformationTabFields(props: Readonly<PersonalInformationFormFieldsProps>) {
  const [showUploadNotice, setShowUploadNotice] = useState(true)
  const [showUploadErrorNotice, setShowUploadErrorNotice] = useState(false)
  const toast = useToasts()
  const [uploadedPassport, setUploadedPassport] = useState<IApplicationDocument | undefined>(
    props.passport &&
      props.passport.attributes.processingResults?.documentTypeConfidence !==
        ConfidenceLevel.MISMATCH
      ? {
          id: props.passport.id,
          uploadDate: new Date(props.passport.attributes.uploadedAt),
          tags: [DocumentTags.PASSPORT],
          url: props.passport.attributes.downloadUrl,
          name: props.passport.attributes.fileName,
          section: '',
          history: [],
          isBlankDatePlaceholder: () => true,
        }
      : undefined,
  )

  const { isUploadingPassport, uploadPassport } = useUploadPassport()
  const {
    passportFiles,
    immigrationFiles,
    immigrationStatus,
    isMailingAddressResidentialAddress,
    residentialAddressCountry,
    residentialAddressLine1,
    residentialAddressLine2,
    residentialAddressCity,
    residentialAddressProvince,
    residentialAddressPostalCode,
  } = useFieldValues([
    'passportFiles',
    'immigrationFiles',
    'immigrationStatus',
    'isMailingAddressResidentialAddress',
    'residentialAddressCountry',
    'residentialAddressLine1',
    'residentialAddressLine2',
    'residentialAddressCity',
    'residentialAddressProvince',
    'residentialAddressPostalCode',
  ])
  const setFieldValues = useSetFieldValues()
  const maxDate = new Date()
  const minDate = new Date(1900, 0, 1)
  maxDate.setFullYear(maxDate.getFullYear() + 10)
  minDate.setFullYear(minDate.getFullYear() - 3)

  const [hasValidVisa, setHasValidVisa] = useState(
    immigrationStatus !== 'NO_STATUS' && !!immigrationStatus,
  )
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const immigrationStatusList = [
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.NO_STATUS,
      label: "I don't have this",
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.STUDY_PERMIT,
      label: 'Study permit',
    },
    { key: crypto.randomUUID(), value: DestinationCountryStatus.WORK_PERMIT, label: 'Work permit' },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.VISITOR,
      label: 'Visitor/Tourist visa',
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.OTHER,
      label: 'Other',
    },
  ]

  const datePickerValidator = createDatePickerValidator(minDate, maxDate, 'day')

  const isExtractingData =
    props.passport?.attributes.documentProcessStatus !==
      DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_COMPLETED &&
    props.passport?.attributes.documentProcessStatus !==
      DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_FAILED

  return (
    <>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            ✈️ Step 1: Provide your passport
          </Heading>
        </Flex>
        {props.passport && showUploadNotice ? (
          <Flex gap={4} direction="column" basis="100%">
            {props.passport?.attributes.documentProcessStatus ===
              DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_COMPLETED &&
            props.passport.attributes.processingResults?.documentTypeConfidence ===
              ConfidenceLevel.HIGH ? (
              <Notice intent="positive">
                Success! The students passport was uploaded. <br />
                We’ve pre-filled the application with the following information. Please review and
                make changes if required.
                <Notice.CloseButton onClick={() => setShowUploadNotice(false)} />
              </Notice>
            ) : null}
            {props.passport?.attributes.documentProcessStatus ===
              DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_COMPLETED &&
            props.passport.attributes.processingResults?.documentTypeConfidence ===
              ConfidenceLevel.LOW ? (
              <Notice intent="warning">
                Some fields couldn't be extracted automatically. Please review and enter the missing
                information.
                <Notice.CloseButton onClick={() => setShowUploadNotice(false)} />
              </Notice>
            ) : null}
            {props.passport?.attributes.documentProcessStatus ===
              DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_FAILED ||
            (props.passport?.attributes.documentProcessStatus ===
              DocumentProcessStatus.DOCUMENT_DATA_EXTRACTION_COMPLETED &&
              props.passport.attributes.processingResults?.documentTypeConfidence ===
                ConfidenceLevel.MISMATCH) ? (
              <Notice intent="warning">
                Please consider uploading the document again. The document classification does not
                match the expected type or may be of poor quality.
                <Notice.CloseButton onClick={() => setShowUploadNotice(false)} />
              </Notice>
            ) : null}
          </Flex>
        ) : null}

        {showUploadErrorNotice ? (
          <Flex gap={4} direction="column" basis="100%">
            <Notice intent="negative">
              Upload failed. Please try again. If the problem persists, check your file format and
              size.
              <Notice.CloseButton onClick={() => setShowUploadErrorNotice(false)} />
            </Notice>
          </Flex>
        ) : null}
        <Flex gap={4} direction="column" basis="100%">
          <Field
            as={FileUploadField}
            allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
            application={props.application}
            disabled={props.disabled || isUploadingPassport}
            fileLimit={UPLOAD_LIMIT}
            fileType={DocumentTags.PASSPORT}
            label={
              uploadedPassport ? (
                ''
              ) : (
                <Text variant={'bodyM'}>
                  Your passport is required to proceed with the application. We'll use it to:
                  <ul>
                    <li>Verify your identity</li>
                    <li>Pre-fill application fields (e.g., name, date of birth, gender)</li>
                  </ul>
                </Text>
              )
            }
            name={`passportFiles`}
            onRemove={(id: string) =>
              setFieldValues({ passportFiles: passportFiles.filter(file => file.id !== id) })
            }
            section={`${props.passport?.id}`}
            showHistory={!!props.disabled}
            helpText={`Add your passport document below, supported file formats: JPG, JPEG, PDF, PNG, max
                number of files: ${UPLOAD_LIMIT}`}
            validate={value => {
              if (!value.length) {
                return 'This field is required'
              }

              if (value.length > UPLOAD_LIMIT) {
                return `This field has a file limit of ${UPLOAD_LIMIT}.`
              }

              return true
            }}
            hideUploadComponent={Boolean(uploadedPassport || isUploadingPassport)}
            showLoading={isUploadingPassport}
            multiple={false}
            presetValue={uploadedPassport ? [uploadedPassport] : undefined}
            onChange={file => {
              uploadPassport(
                transformFileInfoToUploadFileData(file[0], {
                  sectionReference: `${props.passport?.id}`,
                  type: DocumentTags.PASSPORT as unknown as DocumentType,
                }),
                {
                  onSuccess: response => {
                    setUploadedPassport({
                      id: response.data.id,
                      uploadDate: new Date(response.data.attributes.uploadedAt),
                      tags: [DocumentTags.PASSPORT],
                      url: response.data.attributes.downloadUrl,
                      name: response.data.attributes.fileName,
                      section: '',
                      history: [],
                      isBlankDatePlaceholder: () => true,
                    })
                  },
                  onError: err => {
                    setShowUploadErrorNotice(true)
                    toast.negative(err)
                  },
                },
              )
            }}
          />
        </Flex>
      </Flex>

      {uploadedPassport ? (
        <>
          <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Passport number" required />
              ) : (
                <Field
                  as={TextInput as ComponentType<TextInputProps>}
                  label="Passport number"
                  name="passportNumber"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Passport number is required' : false}
                />
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Passport expiry date" required />
              ) : (
                <Field
                  as={DatePicker}
                  label="Passport expiry date"
                  name="passportExpiryDate"
                  maxDate={maxDate.toISOString()}
                  minDate={minDate.toISOString()}
                  disabled={props.disabled}
                  required={!props.disabled ? 'Passport expiry date is required' : false}
                />
              )}
            </Flex.Item>

            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Given name" required />
              ) : (
                <Field
                  as={TextInput as ComponentType<TextInputProps>}
                  label="Given name"
                  name="givenName"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Given name is required' : false}
                />
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Family name (if applicable)" />
              ) : (
                <Field
                  as={TextInput as ComponentType<TextInputProps>}
                  label="Family name (if applicable)"
                  name="familyName"
                  disabled={props.disabled}
                />
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Middle name" />
              ) : (
                <Field
                  as={TextInput as ComponentType<TextInputProps>}
                  label="Middle name"
                  name="middleName"
                  disabled={props.disabled}
                />
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Gender" required />
              ) : (
                <Field
                  as={Select}
                  label="Gender"
                  name="gender"
                  appearance="styled"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Gender is required' : false}
                >
                  {Object.entries(GENDER_NAMES).map(([genderCode, genderName]) => (
                    <Select.Option key={genderCode} label={genderName} value={genderCode} />
                  ))}
                </Field>
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Date of birth" required />
              ) : (
                <Field
                  as={DatePicker}
                  label="Date of birth"
                  name="dateOfBirth"
                  maxDate={maxDate.toISOString()}
                  minDate={minDate.toISOString()}
                  disabled={props.disabled}
                  required={!props.disabled ? 'Date of birth is required' : false}
                  validate={datePickerValidator}
                />
              )}
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Nationality" required />
              ) : (
                <Field
                  as={Combobox}
                  label="Nationality"
                  name="nationality"
                  size="md"
                  placeholder="Select"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Nationality is required' : false}
                >
                  {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                    <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                  ))}
                </Field>
              )}
            </Flex.Item>
          </Flex>

          <Divider />

          <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
            <Flex basis="100%">
              <Heading variant="titleS" level={3}>
                ✈️ Step 2: Provide additional information
              </Heading>
            </Flex>
            <Flex basis="100%">
              <Heading variant="titleS" level={3}>
                <span aria-hidden>🏢</span> Residential Address
              </Heading>
            </Flex>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {isExtractingData ? (
                <LoadingField label="Country" required />
              ) : (
                <Field
                  as={Combobox}
                  label="Country"
                  name="residentialAddressCountry"
                  size="md"
                  placeholder="Select"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Country is required' : false}
                  onChange={value => {
                    if (isMailingAddressResidentialAddress) {
                      setFieldValues({
                        ...copyResidentialAddress({
                          residentialAddressCountry,
                          residentialAddressLine1,
                          residentialAddressLine2,
                          residentialAddressCity,
                          residentialAddressProvince,
                          residentialAddressPostalCode,
                        }),
                        mailingAddressCountry: value as string,
                      })
                    }
                  }}
                >
                  {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                    <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                  ))}
                </Field>
              )}
            </Flex.Item>
            <Flex pb={6} gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
              <Flex.Item basis={{ xs: '100%' }}>
                {isExtractingData ? (
                  <LoadingField label="Address line 1" required />
                ) : (
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Address line 1"
                    name="residentialAddressLine1"
                    disabled={props.disabled}
                    required={!props.disabled ? 'Address is required' : false}
                    helpText="E.g. Street address"
                    onChange={value => {
                      if (isMailingAddressResidentialAddress) {
                        setFieldValues({
                          ...copyResidentialAddress({
                            residentialAddressCountry,
                            residentialAddressLine1,
                            residentialAddressLine2,
                            residentialAddressCity,
                            residentialAddressProvince,
                            residentialAddressPostalCode,
                          }),
                          mailingAddressLine1: value as string,
                        })
                      }
                    }}
                  />
                )}
              </Flex.Item>
              <Flex.Item basis={{ xs: '100%' }}>
                {isExtractingData ? (
                  <LoadingField label="Address line 2" />
                ) : (
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Address line 2"
                    name="residentialAddressLine2"
                    disabled={props.disabled}
                    helpText="E.g. Apartment, suite, unit"
                    onChange={value => {
                      if (isMailingAddressResidentialAddress) {
                        setFieldValues({
                          ...copyResidentialAddress({
                            residentialAddressCountry,
                            residentialAddressLine1,
                            residentialAddressLine2,
                            residentialAddressCity,
                            residentialAddressProvince,
                            residentialAddressPostalCode,
                          }),
                          mailingAddressLine2: value as string,
                        })
                      }
                    }}
                  />
                )}
              </Flex.Item>
              <Flex basis="100%" gap={4}>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  {isExtractingData ? (
                    <LoadingField label="Province/state/region" required />
                  ) : (
                    <Field
                      as={TextInput as ComponentType<TextInputProps>}
                      label="Province/state/region"
                      name="residentialAddressProvince"
                      disabled={props.disabled}
                      required={!props.disabled ? 'Province/state/region is required' : false}
                      onChange={value => {
                        if (isMailingAddressResidentialAddress) {
                          setFieldValues({
                            ...copyResidentialAddress({
                              residentialAddressCountry,
                              residentialAddressLine1,
                              residentialAddressLine2,
                              residentialAddressCity,
                              residentialAddressProvince,
                              residentialAddressPostalCode,
                            }),
                            mailingAddressProvince: value as string,
                          })
                        }
                      }}
                    />
                  )}
                </Flex.Item>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  {isExtractingData ? (
                    <LoadingField label="City/town" required />
                  ) : (
                    <Field
                      as={TextInput as ComponentType<TextInputProps>}
                      label="City/town"
                      name="residentialAddressCity"
                      disabled={props.disabled}
                      required={!props.disabled ? 'City/town is required' : false}
                      onChange={value => {
                        if (isMailingAddressResidentialAddress) {
                          setFieldValues({
                            ...copyResidentialAddress({
                              residentialAddressCountry,
                              residentialAddressLine1,
                              residentialAddressLine2,
                              residentialAddressCity,
                              residentialAddressProvince,
                              residentialAddressPostalCode,
                            }),
                            mailingAddressCity: value as string,
                          })
                        }
                      }}
                    />
                  )}
                </Flex.Item>
              </Flex>
              <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                {isExtractingData ? (
                  <LoadingField label="Postal/zip code" />
                ) : (
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Postal/zip code"
                    name="residentialAddressPostalCode"
                    disabled={props.disabled}
                    onChange={value => {
                      if (isMailingAddressResidentialAddress) {
                        setFieldValues({
                          ...copyResidentialAddress({
                            residentialAddressCountry,
                            residentialAddressLine1,
                            residentialAddressLine2,
                            residentialAddressCity,
                            residentialAddressProvince,
                            residentialAddressPostalCode,
                          }),
                          mailingAddressPostalCode: value as string,
                        })
                      }
                    }}
                  />
                )}
              </Flex.Item>
            </Flex>

            <Flex direction={'column'}>
              <Flex basis={'100%'}>
                <Heading variant="titleS" level={3}>
                  <span aria-hidden>✉️</span> Full Mailing Address
                </Heading>
              </Flex>

              <Flex basis={'100%'}>
                {isExtractingData ? (
                  <LoadingField label="Mailing address is the same as residential address" />
                ) : (
                  <Field
                    as={Checkbox as ComponentType<CheckboxProps>}
                    name="isMailingAddressResidentialAddress"
                    label="Mailing address is the same as residential address"
                    disabled={props.disabled}
                    onChange={value => {
                      if (!value) {
                        setFieldValues({
                          mailingAddressCountry: '',
                          mailingAddressLine1: '',
                          mailingAddressLine2: '',
                          mailingAddressCity: '',
                          mailingAddressProvince: '',
                          mailingAddressPostalCode: '',
                        })
                      } else {
                        setFieldValues(
                          copyResidentialAddress({
                            residentialAddressCountry,
                            residentialAddressLine1,
                            residentialAddressLine2,
                            residentialAddressCity,
                            residentialAddressProvince,
                            residentialAddressPostalCode,
                          }),
                        )
                      }
                    }}
                  />
                )}
              </Flex>

              {!isMailingAddressResidentialAddress ? (
                <>
                  <Flex basis={'100%'} pb={4}>
                    <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                      {isExtractingData ? (
                        <LoadingField label="Country" required />
                      ) : (
                        <Field
                          as={Combobox}
                          label="Country"
                          name="mailingAddressCountry"
                          size="md"
                          placeholder="Select"
                          disabled={props.disabled}
                          required={!props.disabled ? 'Country is required' : false}
                        >
                          {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                            <Combobox.Option
                              key={countryCode}
                              label={countryName}
                              value={countryCode}
                            />
                          ))}
                        </Field>
                      )}
                    </Flex.Item>
                  </Flex>

                  <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
                    <Flex.Item basis={{ xs: '100%' }}>
                      {isExtractingData ? (
                        <LoadingField label="Address line 1" required />
                      ) : (
                        <Field
                          as={TextInput as ComponentType<TextInputProps>}
                          label="Address line 1"
                          name="mailingAddressLine1"
                          disabled={props.disabled}
                          required={!props.disabled ? 'Address is required' : false}
                          helpText="E.g. Street address"
                        />
                      )}
                    </Flex.Item>
                    <Flex.Item basis={{ xs: '100%' }}>
                      {isExtractingData ? (
                        <LoadingField label="Address line 2" />
                      ) : (
                        <Field
                          as={TextInput as ComponentType<TextInputProps>}
                          label="Address line 2"
                          name="mailingAddressLine2"
                          disabled={props.disabled}
                          helpText="E.g. Apartment, suite, unit"
                        />
                      )}
                    </Flex.Item>
                    <Flex basis="100%" gap={4}>
                      <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                        {isExtractingData ? (
                          <LoadingField label="Province/state/region" required />
                        ) : (
                          <Field
                            as={TextInput as ComponentType<TextInputProps>}
                            label="Province/state/region"
                            name="mailingAddressProvince"
                            disabled={props.disabled}
                            required={!props.disabled ? 'Province/state/region is required' : false}
                          />
                        )}
                      </Flex.Item>
                      <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                        {isExtractingData ? (
                          <LoadingField label="City/town" required />
                        ) : (
                          <Field
                            as={TextInput as ComponentType<TextInputProps>}
                            label="City/town"
                            name="mailingAddressCity"
                            disabled={props.disabled}
                            required={!props.disabled ? 'City/town is required' : false}
                          />
                        )}
                      </Flex.Item>
                    </Flex>
                    <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                      {isExtractingData ? (
                        <LoadingField label="Postal/zip code" />
                      ) : (
                        <Field
                          as={TextInput as ComponentType<TextInputProps>}
                          label="Postal/zip code"
                          name="mailingAddressPostalCode"
                          disabled={props.disabled}
                        />
                      )}
                    </Flex.Item>
                  </Flex>
                </>
              ) : null}
            </Flex>
          </Flex>

          {!isExtractingData ? (
            <>
              <Divider />
              <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
                <Flex basis="100%">
                  <Heading variant="titleS" level={3}>
                    ✈️ Step 3: Provide destination status
                  </Heading>
                </Flex>
                <Flex basis="100%" direction="column">
                  <Heading variant="titleS" level={3}>
                    🌎 {props.destinationCountryName || 'Destination Country'} Status
                  </Heading>
                  <Text variant="bodyS">
                    Tell us below your current visa/immigration status in{' '}
                    {props.destinationCountryName}.
                  </Text>
                </Flex>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={Select}
                    label="Status"
                    name="immigrationStatus"
                    appearance="styled"
                    disabled={props.disabled}
                    required={!props.disabled ? 'Status is required' : false}
                    onChange={v => {
                      const fileIds = Object.keys(
                        getObservableFiles({ fileType: DocumentTags.DESTINATION_COUNTRY_STATUS }),
                      )
                      if ((!v || v === 'NO_STATUS') && !!fileIds.length) {
                        fileIds.forEach(id => addPendingDelete(id))
                      }
                      setHasValidVisa(!!v && v !== 'NO_STATUS')
                    }}
                  >
                    {immigrationStatusList.map(status => (
                      <Select.Option key={status.key} label={status.label} value={status.value} />
                    ))}
                  </Field>
                </Flex.Item>
                {hasValidVisa ? (
                  <Flex gap={4} direction="column" basis="100%">
                    <Field
                      as={FileUploadField}
                      allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
                      application={props.application}
                      disabled={props.disabled || !hasValidVisa}
                      fileType={DocumentTags.DESTINATION_COUNTRY_STATUS}
                      label={
                        <Text variant={'bodyM'}>
                          <h3>Provide Study Permit</h3>
                          Please provide a clear and legible copy of your study permit. Ensure that
                          all relevant information, including your name, certificate number, and
                          exam date, is clearly visible.
                        </Text>
                      }
                      helpText={`Supported file formats: JPG, JPEG, PDF, PNG, max number of files: ${UPLOAD_LIMIT}`}
                      name={`immigrationFiles`}
                      onRemove={(id: string) =>
                        setFieldValues({
                          immigrationFiles: immigrationFiles.filter(file => file.id !== id),
                        })
                      }
                      section={`destinationCountryStatus`}
                      validate={value => {
                        const observableFiles = getObservableFiles({
                          fileType: DocumentTags.DESTINATION_COUNTRY_STATUS,
                          sectionReference: 'destinationCountryStatus',
                        })

                        if (
                          hasValidVisa &&
                          !(Object.keys(observableFiles).length || value.length)
                        ) {
                          return 'This field is required'
                        }
                        return true
                      }}
                      showHistory={false}
                    />
                  </Flex>
                ) : null}
              </Flex>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}

type areAddressesEqualParams = {
  application: StudentApplication
}

function copyResidentialAddress({
  residentialAddressCountry,
  residentialAddressLine1,
  residentialAddressLine2,
  residentialAddressCity,
  residentialAddressProvince,
  residentialAddressPostalCode,
}: {
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
}) {
  return {
    mailingAddressCountry: residentialAddressCountry,
    mailingAddressLine1: residentialAddressLine1,
    mailingAddressLine2: residentialAddressLine2,
    mailingAddressCity: residentialAddressCity,
    mailingAddressProvince: residentialAddressProvince,
    mailingAddressPostalCode: residentialAddressPostalCode,
  }
}

function areAddressesEqual(params: areAddressesEqualParams) {
  if (
    !params.application?.attributes?.personalInformation?.addresses ||
    isEqual(
      params.application.attributes?.personalInformation?.addresses?.residentialAddress,
      params.application.attributes?.personalInformation?.addresses?.mailingAddress,
    )
  ) {
    return true
  }
  return false
}
